<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 d-flex">
        <div class="wrapper w-100 m-auto pb-5">
          <FormPlanoDeAcao v-if="loaded" :registry="registry" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormPlanoDeAcao from './FormPlanoDeAcao.vue';
import { PlanosDeAcaoRepository } from '../../libs/repositories';

export default {
  name: 'PlanosDeAcaoEdicao',
  data () {
    return {
      loaded: false,
      registry: null
    };
  },
  components: {
    FormPlanoDeAcao
  },
  async created () {
    const id = +this.$route.params.id;

    const registry = await PlanosDeAcaoRepository.getById(id);

    if (registry) {
      this.registry = registry;
      this.loaded = true;
    }
  }
};

</script>
